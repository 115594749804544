<template>
    <div class="customers" @keydown.esc="this.modalShow = false" tabindex="0">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content">

                <!-- CONTENT -->
                <h3 class="title-secondary">
                    {{ getMyLanguage("compliance","compliance.title") }}
                    <small>{{ getMyLanguage("compliance", "compliance.company.title") }}</small>
                </h3>
                
                <div class="search-bar" v-if="dataCompliance.length && !dataComplianceReview.length">
                    <input type="text" class="form form-search" id="search" v-on:keyup="filterList" :placeholder='getMyLanguage("backoffice","placeholder.search")' autocomplete="off">
                    <div class="btn-filter" @click="filter = true"></div>
                </div>

                <div class="load load-green" v-if="!showContent"></div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="showContent && errorsGet.dataCompliance != false">
                    <h3 class="title-secondary">{{ getMyLanguage("backoffice", "title-error") }}</h3>
                    <!-- <p>{{ getMyLanguage("box-error", errorsGet.dataCompliance) }}</p> -->
                    <p>{{ getMyLanguage("backoffice","has-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataCompliance}}
                    </div>
                </div>
                
                <div class="box no-results" v-if="showContent && !dataCompliance.length && !errorsGet.dataCompliance">{{ getMyLanguage("backoffice","no-data") }}</div>


                <div class="boxes" :class="{reviews: dataComplianceReview.length}" v-if="showContent && dataCompliance.length && !errorsGet.dataCompliance">
                    <div class="box">
                        <div class="scroll scroll-horizontal">

                            <table class="list-result">
                                <thead class="header-list">
                                    <tr>
                                        <th class="date">{{ getMyLanguage("list-result","created") }}</th>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","name") }}</th>
                                        <th class="country">{{ getMyLanguage("list-result","country") }}</th>

                                        <th>{{ getMyLanguage("list-result","statusKYC") }}</th>

                                        <th>{{ getMyLanguage("list-result","statusDocuments") }}</th>

                                        <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                    </tr>
                                </thead>

                                <tbody id="drop-list">
                                    <tr class="list" v-for="(complianceList,index) in dataCompliance" :key="index" @click="viewCompliance(index)">
                                        <td class="date"><span>{{dateTime(complianceList.company.createdAt)}}</span></td>
                                        <td class="fullName text-left"><span>{{complianceList.company.fullName}}</span></td>
                                        <td><span>{{complianceList.company.countryName}}</span></td>
                                        
                                        <td class="status validated" v-if="complianceList.partnersValidated"><span>{{ getMyLanguage("compliance","compliance.status.validated") }}</span></td>
                                        <td class="status pending" v-if="!complianceList.partnersValidated"><span>{{ getMyLanguage("compliance","compliance.status.pending") }}</span></td>
                                        
                                        <td class="status validated" v-if="complianceList.documentsValidated"><span>{{ getMyLanguage("compliance","compliance.status.validated") }}</span></td>
                                        <td class="status pending" v-if="!complianceList.documentsValidated"><span>{{ getMyLanguage("compliance","compliance.status.pending") }}</span></td>
                                        
                                        <td class="status" :class="complianceList.backOfficeStatus"><span>{{ getMyLanguage("compliance","compliance.msgShort." + complianceList.backOfficeStatus) }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>



    <!-- MODAL FILTER -->
        <div class="modal" v-if="filter">
            <div class="close-modal" @click="filter = false"></div>
            <div class="box cont-modal">
                <h3 v-if="filter">{{ getMyLanguage("backoffice","title.filter") }}</h3>
                <div class="filters">
                    <div class="form-group-switch">
                        <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                        <label for="stage" class="form-switch-label">
                            <small>Filter name</small>
                        </label>
                    </div>
                </div>

                <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
            </div>
        </div>
        <div class="overlayModal" v-if="filter"></div>
            


    <!-- MODAL -->
    <div class="modal" v-if="this.modalShow">
        <div @click="this.modalShow = false" class="close-modal"></div>
        <div class="box cont-modal phase-modal">
            <div class="scroll-vertical">

                <!-- RESUME COMPANY -->
                <div class="resume-box resume-box-company">

                    
                    <!-- PARTNERS -->
                    <div v-for="(listPartners,index) in dataModal.partners" :key=" index">
                        <h3>{{listPartners.fullName}}</h3>
                        <div class="alert" v-if='!listPartners.kyc'>{{ getMyLanguage("compliance","compliance.alert.kycPending") }}</div>
                        <div class="box resume resume-parters" :class="{active: listPartners.kyc, pending: !listPartners.kyc}">
                            <p><strong>{{ getMyLanguage("list-result","email") }}: </strong>{{listPartners.email}}</p>
                            <p><strong>{{ getMyLanguage("list-result","phone") }}: </strong>+{{listPartners.phoneNumber}}</p>
                            <p><strong>{{ getMyLanguage("list-result","country") }}: </strong>{{listPartners.country}}</p>
                            <p><strong>{{ getMyLanguage("list-result","DOB") }}: </strong>{{dateTime(listPartners.dob)}}</p>
                            <p>
                                <strong>{{ getMyLanguage("list-result","statusKYC") }}: </strong>
                                <span v-if="listPartners.kyc">{{getMyLanguage("compliance","compliance.lbl.kyc.active")}}</span>
                                <span v-if="!listPartners.kyc">{{getMyLanguage("compliance","compliance.lbl.kyc.pending")}}</span>
                            </p>
                        </div>
                    </div>

                    
                    <!-- COMPANY -->
                    <h3>{{dataModal.company.fullName}}</h3>
                    <!-- <div class="alert" :class="dataModal.backOfficeStatus">{{ getMyLanguage("compliance","compliance.msg." + dataModal.backOfficeStatus) }}</div> -->
                    <div v-if='dataModal.backOfficeStatus != "Accepted" && !dataModal.documentsValidated' class="alert" :class="dataModal.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg." + dataModal.backOfficeStatus)'></div>
                    <div v-if='dataModal.backOfficeStatus === "Pending" && dataModal.documentsValidated' class="alert Review" v-html='getMyLanguage("compliance","compliance.msg.waitingAccept")'></div>

                    <div class="box resume resume-company" :class="{active: dataModal.documentsValidated, pending: !dataModal.documentsValidated}">
                        <p><strong>{{ getMyLanguage("list-result","email") }}: </strong>{{dataModal.company.email}}</p>
                        <p><strong>{{ getMyLanguage("list-result","phone") }}: </strong>+{{dataModal.company.phoneNumber}}</p>
                        <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{dataModal.backOfficeStatus}}</p>
                        <p><strong>{{ getMyLanguage("list-result","country") }}: </strong>{{dataModal.company.countryName}}</p>
                        <p><strong>{{ getMyLanguage("list-result","reason") }}: </strong>{{dataModal.backOfficeStatusReason}}</p>
                    </div>


                    <!-- DOCUMENTS -->
                    <div class="box resume documents">
                        <div class="list-docs" v-for="(listDocs, index) in dataModal.documents" :key="index">
                            <div class="doc" :class="listDocs.accountDocumentStatusExtra">
                                <div class="doc-inf">
                                    <a :href='VUE_APP_BACKOFFICE_API + "/api/v1/international/compliance/kyc/document/" + listDocs.accountDocumentId' target="_blank" class="doc-name hasDoc" v-if="listDocs.fileName != null">
                                        <span>
                                            <small :class="{mandatory: listDocs.accountDocumentMandatory}">{{ getMyLanguage("compliance","compliance.document-type." + listDocs.accountDocumentTypeCode) }}</small>
                                            {{listDocs.fileName}}
                                        </span>
                                    </a>
                                    
                                    <div class="doc-name" v-if="listDocs.fileName === null">
                                        <span>
                                            <small>{{ getMyLanguage("compliance","compliance.document-type." + listDocs.accountDocumentTypeCode) }}</small>
                                            <small class="taxnumber" v-if='listDocs.accountDocumentNumber != null'>{{listDocs.accountDocumentNumber}}</small>
                                        </span>
                                    </div>
                                    
                                    <div class="doc-actions">
                                        <div class="status" :class="listDocs.accountDocumentStatusExtra"><span>{{ getMyLanguage("compliance","compliance.document-status." + listDocs.accountDocumentStatusExtra) }}</span></div>
                                        
                                        <div class="reject" v-if='listDocs.accountDocumentStatusExtra != "NotLoaded"' @click='saveDoc(listDocs.accountId, listDocs.accountDocumentId, listDocs.accountDocumentTypeCode, "Rejected", listDocs.observations)'></div>
                                        <div class="accept" v-if='listDocs.accountDocumentStatusExtra != "NotLoaded"' @click='saveDoc(listDocs.accountId, listDocs.accountDocumentId, listDocs.accountDocumentTypeCode, "Accepted", listDocs.observations)'></div>
                                    </div>
                                </div>

                                <div class="doc-observations">
                                    <input type="text" class="form" v-model="listDocs.observations" :placeholder='getMyLanguage("list-result","observations")'>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="compliance-action" id="approuveCompany" v-if='(dataModal.documentsValidated && dataModal.partnersValidated) && (dataModal.backOfficeStatus != "Accepted")'> -->
                    <div class="compliance-action" :class="dataModal.backOfficeStatus" id="approuveCompany">
                        <div class="alert">{{ getMyLanguage("compliance","compliance.msg." + dataModal.backOfficeStatus) }}</div>

                        <!-- <div v-if='dataModal.backOfficeStatus != "Accepted" && !dataModal.documentsValidated' class="alert" :class="dataModal.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg." + dataModal.backOfficeStatus)'></div>
                        <div v-if='dataModal.backOfficeStatus === "Pending" && dataModal.documentsValidated' class="alert Review" v-html='getMyLanguage("compliance","compliance.msg.waitingAccept")'></div> -->

                        <textarea class="form" v-model="dataCompliance.backOfficeStatusReason" :placeholder='getMyLanguage("list-result","observations")' v-if='dataModal.backOfficeStatus != "Accepted"'></textarea>

                        <div class="bts-compliance" v-if='dataModal.backOfficeStatus != "Accepted"'>
                            <div class="btn-primary btn-cancel" @click='saveCompliance(dataModal.company.accountId, "Rejected")'>{{ getMyLanguage("compliance","compliance.btn-reject") }}</div>
                            <div class="btn-primary btn-disable" v-if="!dataModal.documentsValidated || !dataModal.partnersValidated">{{ getMyLanguage("compliance","compliance.btn-approve") }}</div>
                            <div class="btn-primary" v-if="dataModal.documentsValidated && dataModal.partnersValidated" @click='saveCompliance(dataModal.company.accountId, "Accepted")'>{{ getMyLanguage("compliance","compliance.btn-approve") }}</div>
                        </div>
                    </div>

                    <!-- <div class="compliance-accepted" v-if='dataModal.backOfficeStatus === "Accepted"'>
                        <div class="alert" :class="dataModal.backOfficeStatus">{{ getMyLanguage("compliance","compliance.msg." + dataModal.backOfficeStatus) }}</div>
                    </div> -->
                </div>

            </div>
        </div>
    </div>
    <div class="overlayModal" v-if="this.modalShow === true"></div>
    
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import getMyFavouriteLanguage from "@/services/language.js";


    import moment from 'moment';
    import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            Sidebar
        },

        data () {
            return {
                VUE_APP_BACKOFFICE_API: process.env.VUE_APP_BACKOFFICE_API,
                
                flagSelected: {},
                showContent: false,

                dataCompliance: [],
                dataModal: [],
                dataFlowDoc: {
                    idFile: '',
                    // indexDoc: '',
                    // reason0: false,
                    // reason1: false,
                    // reason2: false,
                    // reason3: false,
                    // reason4: false
                },

                dataActionDoc: {
                    accountId: '',
                    accountDocumentTypeCode: '',
                    status: ''
                },

                docsAccepted: false,

                filter: false,
                filtersParam: {},
                filtersCheck: {},
                filterCustumers: false,
                modalShow: false,

                errorsGet: {
                    dataCompliance: false
                },

                errorsPost: {
                    dataCompliance: false
                },

                flowAction: {
                    observations: false
                }
            }
        },

        async mounted () {
            await apiBackoffice.get('/api/v1/international/compliance/kyc')
            .then(response => {
                this.dataCompliance = response.data
                this.dataComplianceReview = this.dataCompliance.filter(paymentTypes => paymentTypes.account_status === "PendingVerifications")

            })
            .catch(error => {
                this.errorsGet.dataCompliance = error.response.status

                if ( this.errorsGet.dataCompliance == 401 ){
                    localStorage.removeItem('accessToken');
                    this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                    
                } else if ( this.errorsGet.dataCompliance == 403 ){
                    this.$router.push({ name: 'Error403', params: { lang: this.$route.params.lang } })
                }
            })
            
            setTimeout(() => {
                this.showContent = true
            }, 1000);

            // this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("search");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[1];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            viewCompliance(value){
                this.dataModal = this.dataCompliance[value]
                this.dataModal.index = value
                this.modalShow = true
            },

            saveDoc(accountId, accountDocumentId, accountDocumentTypeCode, status, observations){
                this.load = true

                apiBackoffice.post('/api/v1/international/compliance/kyc/' + accountId + "/document", {"status": status, "accountDocumentId": accountDocumentId, "accountDocumentType": accountDocumentTypeCode, "statusReason": observations})
                .then(response => {
                    // this.dataCompliance[index] = response.data

                    this.dataModal = response.data

                    this.updateComplianceList()
                    
                    this.$toast.success(this.getMyLanguage("compliance","compliance.toast.saveDataSuccess"));
                    setTimeout(this.$toast.clear, 6000)

                })
                .catch(error => {
                    console.log(error)
                    
                    this.$toast.error(this.getMyLanguage("compliance","compliance.toast.saveDataError"));
                    setTimeout(this.$toast.clear, 6000)
                    // this.errorsGet.dataAccount = error.response.status

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            },
            
            saveCompliance(accountId, backOfficeStatus){
                this.load = true

                apiBackoffice.post('/api/v1/international/compliance/kyc/' + accountId, {"status": backOfficeStatus,"statusReason": this.dataModal.backOfficeStatusReason})
                .then(response => {
                    // this.dataCompliance[index] = response.data
                    this.dataModal = response.data

                    this.updateComplianceList()

                    this.$toast.success(this.getMyLanguage("compliance","compliance.toast.saveDataSuccess"));
                    setTimeout(this.$toast.clear, 6000)
                })
                .catch(error => {
                    this.$toast.error(this.getMyLanguage("compliance","compliance.toast.saveDataError"));
                    setTimeout(this.$toast.clear, 6000)
                    console.log(error)
                    // this.errorsGet.dataAccount = error.response.status

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            },


            async updateComplianceList(){
                const updateCompliance = await apiBackoffice.get('/api/v1/international/compliance/kyc')
                this.dataCompliance = updateCompliance.data

                console.log(this.dataCompliance.documentsValidated)
                console.log(this.dataCompliance.partnersValidated)
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-compliance.scss" lang="scss" scoped />