<template>
    <div class="sales" @keydown.esc="this.modalShow = false" tabindex="0">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content">
                
                <!-- CONTENT -->
                <h3 class="title-secondary">{{ getMyLanguage("sales", "sales.title") }}</h3>
                
                <div class="search-bar" v-if="dataHistory.length">
                    <input type="text" class="form form-search" id="search" v-on:keyup="filterList" :placeholder='getMyLanguage("backoffice","placeholder.search")' autocomplete="off">
                    <div class="btn-filter" @click="filter = true"></div>
                </div>

                <div class="load load-green" v-if="!showContent"></div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="showContent && errorsGet.dataHistory != false">
                    <h3 class="title-secondary">{{ getMyLanguage("backoffice", "title-error") }}</h3>
                    <!-- <p>{{ getMyLanguage("box-error", errorsGet.dataHistory) }}</p> -->
                    <p>{{ getMyLanguage("backoffice","has-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataHistory}}
                    </div>
                </div>
                
                <div class="box no-results" v-if="showContent && !dataHistory.length && !errorsGet.dataHistory">{{ getMyLanguage("backoffice","no-data") }}</div>

                <div class="boxes" v-if="showContent && dataHistory.length && !errorsGet.dataHistory">
                    <div class="box">
                        <div class="scroll scroll-horizontal">

                            <div class="filter box" v-if="filterSales">
                                <div class="close-filter" @click="filterSales = false"></div>
                                
                                <div class="filters">
                                    <div class="form-group-switch">
                                        <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                                        <label for="stage" class="form-switch-label">
                                            <small>Filter name</small>
                                        </label>
                                    </div>
                                </div>

                                <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
                            </div>

                            <table class="list-result">
                                <thead class="header-list">
                                    <tr>
                                        <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                        <th class="date">{{ getMyLanguage("list-result","payUpTo") }}</th>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","name") }}</th>
                                        <th class="attachment"></th>
                                        <th class="campaign">{{ getMyLanguage("list-result","campaign") }}</th>
                                        <th class="method">{{ getMyLanguage("list-result","method") }}</th>
                                        <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                        <th class="quantity">{{ getMyLanguage("list-result","quantity") }}</th>
                                        <th class="price">{{ getMyLanguage("list-result","price") }}</th>
                                    </tr>
                                </thead>

                                <tbody id="drop-list">
                                    <tr class="list" v-for="(itemHistory,index) in dataHistory" :key="index" @click="viewSale(index)">
                                        <td class="date"><span>{{dateTime(itemHistory.createdAt)}}</span></td>
                                        <td class="date"><span>{{dateTime(itemHistory.payUpTo)}}</span></td>
                                        <td class="fullName text-left"><span>{{itemHistory.personName}}</span></td>
                                        <td class="attachment"><img src="~@/assets/images/icons/attachment.svg" v-if='itemHistory.phasePurchaseReceipt'></td>
                                        <td class="campaign"><span>{{ getMyLanguage("status", itemHistory.phaseName) }}</span></td>
                                        <td class="method"><span>{{ getMyLanguage("status", itemHistory.paymentType) }}</span></td>
                                        <td class="status" :class="itemHistory.phasePurchaseStatus"><span>{{ getMyLanguage("status", itemHistory.phasePurchaseStatus) }}</span></td>
                                        <td class="quantity"><span>{{itemHistory.quantity}} Packs</span></td>
                                        <td class="price" v-if='itemHistory.paymentType != "Crypto" && itemHistory.paymentType != "PIX"'><span>{{itemHistory.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                        <td class="price" v-if='itemHistory.paymentType === "Crypto"'>
                                            <span>
                                                {{(itemHistory.finalPrice * itemHistory.finalPriceAliquot).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT
                                            </span>
                                            <small>({{itemHistory.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}})</small>
                                        </td>
                                        <td class="price" v-if='itemHistory.paymentType === "PIX"'>
                                            <span>
                                                {{(itemHistory.finalPrice * itemHistory.finalPriceAliquot).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}}
                                            </span>
                                            <small>({{itemHistory.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}})</small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- MODAL FILTER -->
        <div class="modal" v-if="filter">
            <div class="close-modal" @click="filter = false"></div>
            <div class="box cont-modal">
                <h3 v-if="filter">{{ getMyLanguage("backoffice","title.filter") }}</h3>
                <div class="filters">
                    <div class="form-group-switch">
                        <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                        <label for="stage" class="form-switch-label">
                            <small>Filter name</small>
                        </label>
                    </div>
                </div>

                <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
            </div>
        </div>
        <div class="overlayModal" v-if="filter"></div>
            


        <!-- MODAL -->
        <div class="modal" v-if="modalShow">
            <div @click="closeModal()" class="close-modal"></div>
            <div class="box cont-modal phase-modal">
                <div class="scroll scroll-horizontal">
                    <h3>{{dataModal.personName}}</h3>

                    <div class="resume-box" :class='{noMargin: dataModal.phasePurchaseStatus === "Paid"}'>

                        <!-- RESUME -->
                        <div class="box resume" :class="dataModal.phasePurchaseStatus">
                            <p><strong>{{ getMyLanguage("list-result","date") }}: </strong>{{dateTimeFull(dataModal.createdAt)}} <small>(<strong>{{ getMyLanguage("list-result","payUpTo") }}: </strong>{{dateTime(dataModal.payUpTo)}})</small></p>
                            <p><strong>{{ getMyLanguage("list-result","campaign") }}: </strong>{{ getMyLanguage("status", dataModal.phaseName) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","method") }}: </strong>{{ getMyLanguage("status", dataModal.paymentType) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{ getMyLanguage("status", dataModal.phasePurchaseStatus) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","quantity") }}: </strong>{{dataModal.quantity}} Packs</p>
                            <p>
                                <strong>{{ getMyLanguage("list-result","price") }}: </strong>
                                <span v-if='dataModal.paymentType != "Crypto" && dataModal.paymentType != "PIX"'>{{dataModal.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span>

                                <span v-if='dataModal.paymentType === "Crypto"'>
                                    {{(dataModal.finalPrice * dataModal.finalPriceAliquot).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT
                                    <small>({{dataModal.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}})</small>
                                </span>
                                
                                <span v-if='dataModal.paymentType === "PIX"'>
                                    {{(dataModal.finalPrice * dataModal.finalPriceAliquot).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}}
                                    <small>({{dataModal.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}})</small>
                                </span>
                            </p>
                            <p><strong>{{ getMyLanguage("list-result","reference") }}: </strong>{{dataModal.reference}}</p>
                            

                            <!-- APPROVED PAYMENT -->
                            <div class="reason" v-if='dataModal.phasePurchaseStatus === "Paid"'>
                                <div v-if='dataModal.paymentType === "Crypto"'>
                                    <p>
                                        {{ getMyLanguage("sales","payment.approved.hash") }}<br>
                                        <a :href='"https://blockscan.com/address/" + dataModal.addresses[0].address' target="_blank">
                                            <strong class="lnk-hask">{{dataModal.reviews.filter(item => item.phasePurchaseReviewId === dataModal.phasePurchaseReviewId)[0].attachment}}</strong>
                                        </a>
                                        <span class="obs">{{dataModal.phasePurchaseStatusReason}}</span>
                                    </p>
                                </div>

                                <div v-if='dataModal.paymentType === "SEPA/SWIFT"'>
                                    <p>{{ getMyLanguage("sales","payment.approved.receipt") }}<br>
                                        <!--<a :href='VUE_APP_BACKOFFICE_API + "api/v1/international/sales/attachment/" + dataModal.reviews.filter(item => item.phasePurchaseReviewId === dataModal.phasePurchaseReviewId)[0].phasePurchaseReviewId' target="_blank">
                                            <strong class="lnk-hask">{{dataModal.reviews.filter(item => item.phasePurchaseReviewId === dataModal.phasePurchaseReviewId)[0].fileName}}</strong>
                                        </a>-->
                                        <span class="obs">{{dataModal.phasePurchaseStatusReason}}</span>
                                    </p>
                                </div>

                                <div v-if='dataModal.paymentType === "PIX" || dataModal.paymentType === "Credit Card"'>
                                    <p><span class="obs" v-html='getMyLanguage("sales","automatically-approved")'></span></p>
                                </div>
                            </div>
                        </div>

                        <!-- ACTION -->
                        <div class="actions" v-if='dataModal.phasePurchaseStatus === "PendingPayment" && (dataModal.paymentType === "SEPA/SWIFT" || dataModal.paymentType === "Crypto")'>
                            <ul>
                                <li @click='openApprove("insert")' class="attach"><span>{{getMyLanguage("actions","action.attach-proof")}}</span></li>
                            </ul>
                        </div>
                    </div>



                    <!-- HISTORY -->
                    <div class="history" v-if='dataModal.reviews.length && dataModal.phasePurchaseStatus != "Paid" && !flowModalStatus.approve'>
                        <h3>{{ getMyLanguage("sales","subtitle.history") }}</h3>

                        <div class="box" v-for="(listHistory,index) in dataModal.reviews" :key="index">
                            <div class="scroll scroll-horizontal">
                                <p class="data-review">
                                    <span class="date">{{dateTimeHistory(listHistory.createdAt)}}</span>
                                    <span class="status send">{{listHistory.reviewType}}</span>
                                    <span class="name">{{listHistory.accountName}}</span>
                                    <span class="btn-primary btn-approve" v-if='listHistory.attachment != null' @click='openApprove("select",listHistory.phasePurchaseReviewId)'>Aprovar</span>
                                </p>
                                <p class="lnk" v-if='listHistory.attachmentType === "hash_tx"'><a :href='"https://blockscan.com/address/" + dataModal.addresses[0].address' target="_blank">{{listHistory.attachment}}</a></p>
                                <p class="lnk" v-if='listHistory.attachmentType === "blob_uri"'><a :href='VUE_APP_BACKOFFICE_API + "api/v1/international/sales/attachment/" + listHistory.phasePurchaseReviewId' target="_blank">{{listHistory.fileName}}</a></p>
                                <p class="msg-review">{{listHistory.observations}}</p>
                                
                            </div>
                        </div>
                    </div>



                    <!-- APPROVE PAYMENT -->
                    <div class="box-action approve-payment" id="ApprovePayment" v-if='flowApprove.approveSelect || flowApprove.approveInsert'>
                        <div v-if="!flowModalStatus.approve">

                            <!-- APPROVE SELECT -->
                            <div v-if="flowApprove.approveSelect">
                                <h3>{{getMyLanguage("actions","action.approve-payment")}}</h3>
                                
                                <div class="list-attachments">
                                    <div class="scroll scroll-horizontal">
                                        <div v-for='(listAttachmentsApprove,index) in dataModal.reviews' :key="index">
                                            <div class="attachment" v-if='listAttachmentsApprove.attachment != null'>
                                                <span class="date">{{dateTimeHistory(listAttachmentsApprove.createdAt)}}</span>
                                                
                                                <div class="form-group-radio">
                                                    <input type="radio" :value="dataSaleAction.phasePurchaseReviewId" :id="listAttachmentsApprove.phasePurchaseReviewId" name="proofSelected" class="form-radio" @click='dataSaleAction.phasePurchaseReviewId = listAttachmentsApprove.phasePurchaseReviewId' :checked='(dataSaleAction.phasePurchaseReviewId == listAttachmentsApprove.phasePurchaseReviewId)'>
                                                    <label :for="listAttachmentsApprove.phasePurchaseReviewId" class="form-radio-label">
                                                        <span v-if='listAttachmentsApprove.fileName != null'>{{listAttachmentsApprove.fileName}}</span>
                                                        <span v-if='listAttachmentsApprove.fileName === null'>{{listAttachmentsApprove.attachment}}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- ATTACHMENT INSERT -->
                            <div v-if='flowApprove.approveInsert && dataModal.paymentType == "SEPA/SWIFT"'>
                                <h3>{{getMyLanguage("actions","action.attach-proof")}}</h3>
                                <div class="send-receipt">
                                    <div>
                                        <p><strong>{{getMyLanguage("sales","instructions.receipt.txt")}}</strong></p>
                                        <label for="LblUploadReceipt" class="btn-upload" :class='{fileSelected: uploadReceipt.File != null, error: uploadReceipt.error}'>
                                            <span class="send">{{getMyLanguage("sales","instructions.receipt.upload.select-file")}}</span>
                                            <span class="selected">{{getMyLanguage("sales","instructions.receipt.upload.selected-file")}}</span>

                                            <input type="file" id="LblUploadReceipt" ref="file" @change="uploadFileReceipt()">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <label v-if='flowApprove.approveInsert && dataModal.paymentType === "Crypto"'>
                                {{getMyLanguage("labels","label.hash")}}
                                <div class="form-lnk">
                                    <input type="text" v-model="dataModal.Receipt" class="form" :class="{error: validation.Hash}">
                                    <!-- <div class="lnk"></div> -->
                                </div>
                            </label>

                            <label v-if="flowApprove.approveInsert">
                                {{ getMyLanguage("sales","observations-receipt") }}
                                <textarea v-model="dataModal.Observations" class="form" :class="{error: validation.Observations}"></textarea>
                            </label>

                            <label v-if="flowApprove.approveSelect">
                                {{getMyLanguage("labels","label.reason-approval")}}
                                <textarea v-model="dataSaleAction.statusReason" class="form" :class="{error: validation.statusReason}"></textarea>
                            </label>

                            <div class="bts">
                                <div class="btn-primary" @click="validateSendReview()" v-if="!flowModal.load && flowApprove.approveInsert">{{getMyLanguage("actions","action.attach")}}</div>
                                <div class="btn-primary" @click="validateApprovePayment()" v-if="!flowModal.load && flowApprove.approveSelect">{{getMyLanguage("actions","action.confirm")}}</div>
                                <div class="btn-primary btn-load-small" v-if="flowModal.load"></div>
                            </div>
                        </div>


                        <div class="box-success" v-if="flowModalStatus.approve">
                            <h3 class="title-secondary">{{getMyLanguage("actions","action.success")}}</h3>
                            <p>{{ getMyLanguage("sales","success.payment-approved") }}O pagamento foi aprovado</p>
                        </div>
                        
                        <div class="box-error" v-if="errorsPost.dataSaleAction != false">
                            <h3 class="title-secondary">{{ getMyLanguage("backoffice", "title-error") }}</h3>
                            <p>{{ getMyLanguage("backoffice","has-error") }}</p>
                            <div class="cod-error">
                                Cód.: {{errorsPost.dataSaleAction}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="overlayModal" v-if="modalShow"></div>
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import getMyFavouriteLanguage from "@/services/language.js";


    import moment from 'moment';
    import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            Sidebar
        },
        
        data () {
            return {
                VUE_APP_BACKOFFICE_API: process.env.VUE_APP_BACKOFFICE_API,
                
                flagSelected: {},
                showContent: false,
                dataHistory: [],

                errorsPut: {
                    upload: false
                },

                errorsPost: {
                    dataSaleAction: false,
                    hash: false
                },

                errorsGet: {
                    dataHistory: false
                },

                dataModal: {
                    load: false,
                    index: "",
                    reviewSuccess: false,
                    expired: true,
                    Observations: ""
                },

                validation: {
                    Hash: false,
                    Observations: false,
                    statusReason: false
                },

                uploadReceipt: {
                    File: '',
                    error: false
                },

                modalPending: false,
                modalPaid: false,
                modalCancelled: false,

                dataSaleAction: {
                    purchaseStatus: "",
                    statusReason: ""
                },

                flowModal: {
                    load: false
                },

                flowModalStatus: {
                    approve: false,
                    cancel: false
                },

                flowApprove: {
                    approveSelect: false,
                    approveInsert: false
                },

                filter: false,
                filtersParam: {},
                filtersCheck: {},
                filterSales: false,
                modalShow: false,
            }
        },

        async mounted () {
            // const urlParams = new URLSearchParams(window.location.search);
            // const stageParams = urlParams.get('stage');
            // const statusParams = urlParams.get('status');

            // if ( urlParams != '' ){
            //     this.filtersParam.stage = stageParams
            //     this.filtersParam.status = statusParams
            // }

            await apiBackoffice.get('/api/v1/international/sales/purchases')
            .then(response => {
                this.dataHistory = response.data
            })
            .catch(error => {
                this.errorsGet.dataHistory = error.response.status

                if ( this.errorsGet.dataHistory == 401 ){
                    localStorage.removeItem('accessToken');
                    this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                    
                } else if ( this.errorsGet.dataHistory == 403 ){
                    this.$router.push({ name: 'Error403', params: { lang: this.$route.params.lang } })
                }
            })
            
            setTimeout(() => {
                this.showContent = true
            }, 1000);

            // this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeHistory(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("search");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[2];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },



            // OPEN MODAL > VIEW SALE
            viewSale(value){
                this.dataModal = this.dataHistory[value]
                this.dataModal.index = value

                this.uploadReceipt.File = null
                // this.dataModal.Receipt = ""
                this.dataModal.Observations = ""

                this.dataModal.modal = true
                this.dataSaleAction.statusReason = ""

                this.flowModalStatus.approve = false

                this.modalShow = true

                // CONDITION DATA
                this.dataModal.expired = false
                // 

                // if ( this.dataModal.phasePurchaseStatus === "PendingPayment" ){
                //     this.modalPending = true
                // }
                
                // if ( this.dataModal.phasePurchaseStatus === "Paid" ){
                //     this.modalPaid = true
                // }
                
                // if ( this.dataModal.phasePurchaseStatus === "Cancelled" ){
                //     this.modalCancelled = true
                // }
            },



            // OPEN APPROVE
            openApprove(value, id){
                if ( value === "insert" ){
                    this.flowApprove.approveInsert = true
                    this.flowApprove.approveSelect = false

                    // this.dataModal.Receipt = ""
                    this.dataModal.Observations = ""
                    this.uploadReceipt.File = null

                    this.validation.Hash = false
                    this.validation.Observations = false
                }
                
                if ( value === "select" ){
                    this.flowApprove.approveSelect = true
                    this.flowApprove.approveInsert = false
                }

                this.dataSaleAction.purchaseStatus = "Paid"
                this.dataSaleAction.phasePurchaseReviewId = id

                setTimeout(() => {
                    window.location.href = "#ApprovePayment"
                }, 100);
            },



            // CLOSE MODAL
            closeModal(){
                this.modal = false
                this.modalShow = false

                this.flowApprove.approveSelect = false
                this.flowApprove.approveInsert = false
                
                this.uploadReceipt.File = null
                this.dataModal.reviewSuccess = false
                this.validation.Hash = true
                this.errorsPost.dataSaleAction = false
                this.dataSaleAction.purchaseStatus = ""
                // this.dataModal.receipt = ""
                this.dataModal.Observations = ""
            },

            closeModalPending(){
                this.modalPending = false
                this.modalShow = false

                this.flowApprove.approveSelect = false
                this.flowApprove.approveInsert = false
                
                this.errorsPost.dataSaleAction = false
                this.dataSaleAction.purchaseStatus = ""
                // this.dataModal.receipt = ""
                this.dataModal.Observations = ""
            },
            
            closeModalPaid(){
                this.modalPaid = false
                this.modalShow = false
            },
            
            closeModalCancelled(){
                this.modalPaid = false
                this.modalShow = false
            },



            cancelPurchase(id) {
            this.dataModal.load = true;

            apiBackoffice.delete("/api/v1/ico/token_sale/cancel/" + id)
                .then((response) => {
                    this.registerId = response.data.id;

                    const languageRedirect = String(window.location.pathname).split("/")[1];
                    window.location.href = "/" + languageRedirect + "/client-space";
                })
                .catch((error) => {
                    this.dataModal.load = false;
                    this.errorMessage = error.message;
                    console.error("There was an error!", JSON.stringify(error));

                    this.$toast.error(this.errorMessage);

                    setTimeout(this.$toast.clear, 3000);
                });
            },



            validateSendReview(){
                if ( this.dataModal.Observations === "" ){
                    this.validation.Observations = true
                } else {
                    this.validation.Observations = false
                }

                if ( this.dataModal.paymentType === "SEPA/SWIFT" ){
                    if ( this.uploadReceipt.File === null ){
                        this.uploadReceipt.error = true
                    } else {
                        this.uploadReceipt.error = false
                    }

                    if ( this.uploadReceipt.error === false && this.validation.Observations === false ){
                        this.sendReview()
                    }
                }

                if ( this.dataModal.paymentType === "Crypto" ){
                    if ( this.dataModal.Receipt != "" ){
                        if ( this.dataModal.Receipt.match(/^0x([A-Fa-f0-9]{64})$/) ) {
                            this.validation.Hash = false
                        } else {
                            this.validation.Hash = true
                        }
                    } else if ( this.dataModal.Receipt === "" ){
                        this.validation.Hash = true
                    }
                    else {
                        this.validation.Hash = false
                    }

                    if ( this.validation.Hash === false && this.validation.Observations === false ){
                        this.sendReview()
                    }
                }
            },


            validateApprovePayment(){
                if ( this.dataSaleAction.statusReason === "" ){
                    this.validation.statusReason = true
                } else {
                    this.validation.statusReason = false
                    this.approvePayment()
                }

            },

            uploadFileReceipt() {
                this.uploadReceipt.File = this.$refs.file.files[0];
                console.log(this.uploadReceipt)
            },

            sendReview(){
                this.flowModal.load = true

                const formData = new FormData();
                formData.append('File', this.uploadReceipt.File);
                formData.append('PhasePurchaseId', this.dataModal.phasePurchaseId);
                formData.append('Receipt', this.dataModal.Receipt);
                formData.append('Observations', this.dataModal.Observations);
                // console.log(JSON.stringify(formData))

                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                apiBackoffice.put("/api/v1/international/sales/purchase", formData, { headers })
                .then(response => {
                    
                    this.flowModal.load = false
                    this.dataModal.reviewSuccess = true

                    this.updatePurchases();

                    setTimeout(() => {
                        this.dataModal.reviewSuccess = false
                    }, 5000);
                    
                    console.log(response)
                    // console.clear()
                })
                .catch(error => {
                    this.errorsPut.upload = error.response.data
                    this.errorsPut.upload = JSON.stringify(error.response.status)
                    
                    this.flowModal.load = false
                })
            },

            async updatePurchases(){
                const updatePurchases = await apiBackoffice.get('/api/v1/international/sales/purchases')
                this.dataHistory = updatePurchases.data
            
                this.changePayment(this.dataModal.index)
            },

            changePayment(value) {
                this.dataModal = this.dataHistory[value]

                this.flowApprove.approveInsert = false
            },



            approvePayment(){
                this.flowModal.load = true

                apiBackoffice.post("/api/v1/international/sales/purchases/status/" + this.dataModal.phasePurchaseId, this.dataSaleAction)
                .then(response => {
                    this.flowModalStatus.approve = true
                    console.log(response.data)
                    console.clear()

                    this.flowModal.load = false

                    // UPDATE dataHistory
                    apiBackoffice.get('/api/v1/international/sales/purchases')
                    .then(response => {
                        this.dataHistory = response.data
                    })
                    .catch(error => {
                        this.errorsGet.dataHistory = error.response.status

                        if ( this.errorsGet.dataHistory == 401 ){
                            localStorage.removeItem('accessToken');
                            this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                            
                        } else if ( this.errorsGet.dataHistory == 403 ){
                            this.$router.push({ name: 'Error403', params: { lang: this.$route.params.lang } })
                        }
                    })
                })
                .catch(error => {
                    this.errorsPost.dataSaleAction = JSON.stringify(error.response.status)
                    
                    this.flowModal.load = false
                })
            },
            
            
            // cancelPurchase(){
            //     this.flowModal.load = true

            //     apiBackoffice.post("/api/v1/international/sales/purchases/status/" + this.dataModal.phasePurchaseId, this.dataSaleAction)
            //     .then(response => {
            //         this.flowModalStatus.cancel = true
            //         console.log(response.data)
            //         console.clear()

            //         this.flowModal.load = false

            //         // UPDATE dataHistory
            //         apiBackoffice.get('/api/v1/international/sales/purchases')
            //         .then(response => {
            //             this.dataHistory = response.data
            //         })
            //         .catch(error => {
            //             this.errorsGet.dataHistory = error.response.status

            //             if ( this.errorsGet.dataHistory == 401 ){
            //                 localStorage.removeItem('accessToken');
            //                 this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                            
            //             } else if ( this.errorsGet.dataHistory == 403 ){
            //                 this.$router.push({ name: 'Error403', params: { lang: this.$route.params.lang } })
            //             }
            //         })
            //     })
            //     .catch(error => {
            //         this.errorsPost.dataSaleAction = JSON.stringify(error.response.status)
                    
            //         this.flowModal.load = false
            //     })
            // },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-sales.scss" lang="scss" scoped />